.home-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #131c26;
  text-align: center;
}

.home-main {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.home-devlink {
  color: #61dafb;
  margin-top: 100px;
  font-size: 10px;
  display: none;
}
