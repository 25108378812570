.mp-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: #131c26;
}

.mp-container h2 {
  text-align: center;
}

.mp-link {
  color: #61dafb;
}

.mp-main {
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: white;
}

.mp-supportbox {
  margin: 50px 10px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.mp-contentminbox {
  min-height: 50vh;
}
